import React from "react";
import PropTypes from "prop-types";

const historicPerformance = props => {
  const selectedOption = props.historicalOptions.filter(item => {
    return (
      item.type.toLowerCase() === props.historicPerformanceOption.toLowerCase()
    );
  });

  const getValues = value => {
    switch (value) {
      case "historicalHitrateWeighting":
        return "Hit Rate Weighting";
      case "historicalPricedrateWeight":
        return "Priced Rate Weighting";
      case "historicalPeriodDays":
        return "Historical Time Period(days)";
      case "historicalMinObservations":
        return "Minimum Observation";
      default:
        return value;
    }
  };

  const getDataValue = value => {
    return selectedOption[0][value];
  };

  return (
    <div className="col-xs-12 col-sm-12 col-md-12">
      <div className="panel panel-primary">
        <div className="panel-body">
          <div className="col-xs-6 col-sm-6 col-md-6">
            <label className="text-center"> Liquidity Score:</label>
          </div>
          <div className="col-xs-6 col-sm-6 col-md-6 well well-sm">
            <ul className="nav nav-pills">
              {props.historicalOptions.map((item, index) => {
                return (
                  <li
                    key={index}
                    className={
                      item.type === props.historicPerformanceOption
                        ? "active"
                        : ""
                    }
                    onClick={() => props.historicOptionsChange(item.type)}
                  >
                    <a>{item.type}</a>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="col-xs-6 col-sm-6 col-md-6 mb-10">
            <span>{getValues("historicalHitrateWeighting")}</span>
         
            <input
              id="lowhistoricalHitrateWeighting"
              className="input-sm sides"
              type="number"
              name="fname"
              min="0"
              max="100"
              value={getDataValue("historicalHitrateWeighting")}
              onChange={event =>
                props.highhistoricalChange(
                  event.target.value,
                  "historicalHitrateWeighting"
                )
              }
            />
          </div>
          <div className="col-xs-6 col-sm-6 col-md-6 mb-10">
            <span>{getValues("historicalPricedrateWeight")}</span>
            <input
              id="lowhistoricalPricedrateWeight"
              className="input-sm sides"
              type="number"
              name="fname"
              min="0"
              max="100"
              value={getDataValue("historicalPricedrateWeight")}
              onChange={event =>
                props.highhistoricalChange(
                  event.target.value,
                  "historicalPricedrateWeight"
                )
              }
            />
          </div>
          <div className="col-xs-6 col-sm-6 col-md-6 mb-10">
            <span>{getValues("historicalPeriodDays")}</span>
            <select
              id="lowhistoricalPeriodDays"
              className="input-sm sides"
              value={getDataValue("historicalPeriodDays")}
              onChange={event =>
                props.highhistoricalChange(
                  event.target.value,
                  "historicalPeriodDays"
                )
              }
            >
              <option value="30">30</option>
              <option value="60">60</option>
            </select>
          </div>
          <div className="col-xs-6 col-sm-6 col-md-6 mb-10">
            <span>{getValues("historicalMinObservations")}</span>
            <input
              id="lowhistoricalMinObservations"
              type="number"
              name="fname"
              className="input-sm sides"
              min="2"
              max="100"
              value={
                getDataValue("historicalMinObservations") &&
                getDataValue("historicalMinObservations") < 2
                  ? 2
                  : getDataValue("historicalMinObservations")
              }
              onChange={event =>
                props.highhistoricalChange(
                  event.target.value && event.target.value < 2
                    ? 2
                    : event.target.value,
                  "historicalMinObservations"
                )
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default historicPerformance;

historicPerformance.propTypes = {
  historicPerformanceOption: PropTypes.string.isRequired,
  historicOptionsChange: PropTypes.func.isRequired,
  highhistoricalChange: PropTypes.func.isRequired
};
