import React from "react";
import PropTypes from "prop-types";

const generalPreferences = props => {
  const configSettingsArray = [
	"Go To All",  
    "Top Dealers",
    "Go To All & Top Dealers"
  ];

  let bucketData = props.bucketOptions.filter(
    item => {
        return parseInt(item.type) === 1;
    }
  );


  return (
    <div className="panel panel-primary">
      <div className="panel-body">
        <div className="row form-group">
          <div className="col-xs-12 col-md-12 mb-10">
            <label className="margin-sides">
              Smart Selection Configuration Setting:
            </label>
            <select
              id="ConfigSettings"
              className="input-sm margin-sides"
              value={props.smartSelectType}
              onChange={event => props.goToChanged(event.target.value)}
            >
              {configSettingsArray.map((setting, index) => {
                return (
                  <option key={index} value={setting}>
                    {setting}
                  </option>
                );
              })}
            </select>
          </div>
          {props.smartSelectType.toLowerCase() === "go to all & top dealers" ? (
            <div className="col-xs-12 col-md-12">
              <span className="margin-sides">
                Top Dealers when inquiry size is greater than (000s) {" "}
              </span>
              <input
                type="number"
                name="fname"
                className={`input-sm
                ${bucketData[0].selectAllSize === ""
                      ? "error"
                      : null}`}
                min="0"
                value={bucketData[0].selectAllSize}
                onChange={event =>
                  props.selectAllSizeChanged(parseInt(event.target.value), 1)
                }
                onBlur={event =>
              	  props.bucketSizeReset()
              	}
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default generalPreferences;

generalPreferences.propTypes = {
  goToChanged: PropTypes.func.isRequired,
  smartSelectType: PropTypes.string.isRequired,
  bucketOneSize: PropTypes.number.isRequired,
  bucketOneSizeChanged: PropTypes.func.isRequired,
  bucketSizeReset: PropTypes.func.isRequired
};
