import React from "react";
import PropTypes from "prop-types";
import "./isins-issuers-excludes.css";

const isinIssuersExcludes = props => {
  return (
    <div className="col-xs-5 col-md-5 pt-10 sides">
      <div className="panel panel-primary">
        <div className="panel-heading">{props.type}</div>
        <div className="panel-body">
          <div className="col-xs-12 col-md-12 isins">
            <div className="form-group form-group-sm">
              <label className="col-xs-12 col-md-12 text-center">
                {" "}
                ISINS and Issuers
              </label>
              <textarea
                className="form-control"
                id="isinsExcludes"
                rows="3"
                value={props.isinIssuersTextExcludes}
                onChange={event =>
                  props.isinIssuersChangeExcludes(event.target.value)
                }
              />
            </div>
          </div>
          <div className="col-xs-12 col-md-12 isins">
            <div className="form-group form-group-sm">
              <label className="col-xs-12 col-md-12 text-center">
                {" "}
                Dealers
              </label>
              <textarea
                className={`form-control isins ${
                  !props.isinDealersTextExcludes.length &&
                  props.isinIssuersTextExcludes.length
                    ? "error"
                    : ""
                }`}
                id="isinsExcludes"
                rows="3"
                value={props.isinDealersTextExcludes}
                onChange={event =>
                  props.isinDealersChangeExcludes(event.target.value)
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default isinIssuersExcludes;

isinIssuersExcludes.propTypes = {
  type: PropTypes.string.isRequired,
  isinDealersTextExcludes: PropTypes.string,
  isinIssuersTextExcludes: PropTypes.string,
  isinIssuersChangeExcludes: PropTypes.func,
  isinDealersChangeExcludes: PropTypes.func
};
