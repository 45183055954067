import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import "./dealer-selection-priority-action.css";

const dealerSelectionPriorityAction = props => {
  return (
    <tr id="go">
      <td />
      <td />
      <td />
      <td>
        {(props.dealerPriorityList.length < props.dealerSelectionsOptionsListCount) && (props.unSelectedListCount === 0) ? (
          <FontAwesomeIcon icon={faPlus} onClick={() =>props.addNewPriority(props.tdBucket)} size="lg" className="go"/>
        ) : null}
      </td>
    </tr>
  );
};

export default dealerSelectionPriorityAction;

dealerSelectionPriorityAction.propTypes = {
  dealerSelectionsOptionsListCount: PropTypes.number.isRequired,
  unSelectedListCount: PropTypes.number.isRequired,
  tdBucket: PropTypes.number.isRequired,
  addNewPriority: PropTypes.func.isRequired
};
