import React from "react";
import PropTypes from "prop-types";

const timings = props => {
  let value;
  let minHours;
  if (props.dealerPassedThresholdTime == null || props.dealerPassedThresholdTime === "") {
    value = "";
    minHours = "mins/hrs";
  } else {
    value = props.dealerPassedThresholdTime;
    minHours = "";
  }

  return (
    <React.Fragment>
    <select
      id="selectionExclusionTiming"
      className={`input-sm ${props.dlrsLastPassed && !value ? 'error' : null}`}
      value={value}
      onChange={evt => props.timingsChanged(evt.target.value, props.tdBucket)}
      disabled={!props.dlrsLastPassed}
    >
      <option  value="" />
      {props.timingsArray.map((timing, index) => {
        return (
          <option key={index} value={timing}>
            {timing >= 60?timing/60:timing}{timing >= 60?timing > 60?' hrs':' hr' :' mins'}
          </option>
        );
      })}
    </select>
    <label className="lft-5 font-norm">{minHours}</label>
    </React.Fragment>
  );
};

export default timings;

timings.propTypes = {
  dealerPassedThresholdTime: PropTypes.number,
  timingsChanged: PropTypes.func.isRequired,
  tdBucket: PropTypes.number
};
