import React from "react";
import "./error-page.css";

const errorPage = props => {
  return (
    <section class="page_404">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 col-md-12">
            <div class="col-sm-10 col-sm-offset-1  text-center message">
              <div class="four_zero_four_bg">
                <h1 class="text-center ">{props.dataErrorCode}</h1>
              </div>

              <div class="contant_box_404">
                <h3 class="h2">Looks like we have a technical glitch</h3>

                <p>{props.dataErrorMessage}</p>

                <a href="" class="link_404" onclick="window.location.reload()">
                  Reload
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default errorPage;
