import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import SmartSelect from "./containers/smart-select/smart-select";
import * as serviceWorker from "./serviceWorker";
import "bootstrap/dist/css/bootstrap.min.css";
import { createStore, applyMiddleware } from "redux";
import reducer from "./store/reducers/reducer";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

const store = createStore(reducer, composeWithDevTools(applyMiddleware(thunk)));

ReactDOM.render(
  <Provider store={store}>
    <SmartSelect />
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
