import * as actionTypes from "../actions/actions";

export const setEnvironment = () => {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_ENVIRONMENT
    });
    dispatch({
      type: actionTypes.SET_HAS_SELECTION
    });
  };
};

export const dealerPriorityChange = (value, changeIndex, tdBucket) => {
  return dispatch => {
    dispatch({
      type: actionTypes.SELECTION_PRIORITY_VALUE_CHANGE,
      payload: { value, changeIndex, tdBucket }
    });
    dispatch({
      type: actionTypes.CHECK_ERROR
    });
    dispatch({
      type: actionTypes.SET_HAS_SELECTION
    });
  };
};

export const selectionPriorityQuantityChange = (value, changeIndex, tdBucket) => {
  return dispatch => {
    dispatch({
      type: actionTypes.SELECTION_PRIORITY_QUANTITY_CHANGE,
      payload: { value, changeIndex, tdBucket }
    });
    dispatch({
      type: actionTypes.CHECK_ERROR
    });
  };
};

export const isinIssuersChangeExcludes = value => {
  return dispatch => {
    dispatch({
      type: actionTypes.ISIN_ISSUERS_CHANGE_EXCLUDES,
      payload: { value }
    });
    dispatch({
      type: actionTypes.CHECK_ERROR
    });
  };
};

export const isinIssuersChangeIncludes = value => {
  return dispatch => {
    dispatch({
      type: actionTypes.ISIN_ISSUERS_CHANGE_INCLUDES,
      payload: { value }
    });
    dispatch({
      type: actionTypes.CHECK_ERROR
    });
  };
};

export const isinDealersChangeExcludes = value => {
  return dispatch => {
    dispatch({
      type: actionTypes.ISIN_DEALERS_CHANGE_EXCLUDES,
      payload: { value }
    });
    dispatch({
      type: actionTypes.CHECK_ERROR
    });
  };
};

export const isinDealersChangeIncludes = value => {
  return dispatch => {
    dispatch({
      type: actionTypes.ISIN_DEALERS_CHANGE_INCLUDES,
      payload: { value }
    });
    dispatch({
      type: actionTypes.CHECK_ERROR
    });
  };
};

export const includeTopDealers = (value, tdBucket) => {
  return dispatch => {
    dispatch({
      type: actionTypes.INCLUDE_TOP_DEALERS_VALUE_CHANGE,
      payload: { value,tdBucket }
    });
    dispatch({
      type: actionTypes.CHECK_ERROR
    });
  };
};

export const dealerPriorityDrop = (evt, changeIndex, tdBucket) => {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_PRIORITY_TO_BE_REMOVED,
      payload: { value: evt, changeIndex, tdBucket }
    });
    dispatch({
      type: actionTypes.SET_NEW_PRIORITY_LOCATION,
      payload: { value: evt, changeIndex, tdBucket }
    });
  };
};

export const removeSelectionPriorityRow = (priority, tdBucket) => {
  return dispatch => {
    dispatch({
      type: actionTypes.REMOVE_SELECTION_PRIORITY_ROW,
      payload: { arrayPriority: priority, tdBucket }
    });
    dispatch({
      type: actionTypes.CHECK_ERROR
    });
    dispatch({
      type: actionTypes.SET_HAS_SELECTION
    });
  };
};

export const addNewPriority = (tdBucket) => {
  return dispatch => {
    dispatch({
      type: actionTypes.ADD_NEW_PRIORITY,
      payload: {tdBucket}
    });
    dispatch({
      type: actionTypes.CHECK_ERROR
    });
    dispatch({
      type: actionTypes.SET_HAS_SELECTION
    });
  };
};

export const updateGroup = (group, groupParent, changeIndex, tdBucket) => {
  return dispatch => {
    dispatch({
      type: actionTypes.UPDATE_GROUP,
      payload: { value: group, groupParent, tdBucket }
    });
    dispatch({
      type: actionTypes.SELECTION_PRIORITY_VALUE_CHANGE,
      payload: { value: groupParent, changeIndex, group, tdBucket:tdBucket }
    });
    dispatch({
      type: actionTypes.SET_HAS_SELECTION
    });
  };
};

export const segmentClickChanged = value => {
  return dispatch => {
    dispatch({
      type: actionTypes.SEGMENT_CHANGED,
      payload: { value }
    });
    dispatch({
      type: actionTypes.FLIP_DUPLICATE_SMART_SELECT_DATA,
      payload: { value }
    });
    dispatch({
      type: actionTypes.SET_HAS_SELECTION
    });
  };
};

export const gtaAxedOtherSideAlwaysChanged = value => {
  return dispatch => {
    dispatch({
      type: actionTypes.GTA_AXED_ON_OTHER_SIDE_ALWAYS_CHANGE,
      payload: { value }
    });
    dispatch({
      type: actionTypes.SET_HAS_SELECTION
    });
  };
};

export const gtaInventorySizeZeroMySideChanged = value => {
  return dispatch => {
    dispatch({
      type: actionTypes.GTA_INVENTORY_SIZE_ZERO_MY_SIDE_CHANGE,
      payload: { value }
    });
  };
};

export const tdInventorySizeZeroMySideChanged = (value, tdBucket) => {
  return dispatch => {
    dispatch({
      type: actionTypes.TD_INVENTORY_SIZE_ZERO_MY_SIDE_CHANGE,
      payload: { value, tdBucket }
    });
  };
};

export const axedOtherSideAlwaysChanged = (value, tdBucket) => {
  return dispatch => {
    dispatch({
      type: actionTypes.TOP_DEALERS_AXED_ON_OTHER_SIDE_ALWAYS_CHANGE,
      payload: { value, tdBucket }
    });
    dispatch({
      type: actionTypes.SET_HAS_SELECTION
    });
  };
};

export const gtaAxedOtherSideNeverChanged = value => {
  return dispatch => {
    dispatch({
      type: actionTypes.GTA_AXED_ON_OTHER_SIDE_NEVER_CHANGE,
      payload: { value }
    });
    dispatch({
      type: actionTypes.SET_HAS_SELECTION
    });
  };
};

export const resetCurrentSegment = () => {
  return dispatch => {
    dispatch({
      type: actionTypes.RESET_CURRENT_SEGMENT
    });
    dispatch({
      type: actionTypes.CHECK_ERROR
    });
    dispatch({
      type: actionTypes.SET_HAS_SELECTION
    });
  };
};

export const setCopyCheckboxSettingsState = (checked, segment) => {
  return dispatch => {
    dispatch({
      type: actionTypes.UPDATE_COPY_SETTINGS_CHECKBOX_STATE,
      payload: { checked, segment }
    });
  };
};

export const copyItemsTo = () => {
  return dispatch => {
    dispatch({
      type: actionTypes.COPY_ITEMS_TO
    });
    dispatch({
      type: actionTypes.CHECK_ERROR
    });
    dispatch({
      type: actionTypes.CLOSE_MODAL
    });
  };
};

export const responseRateSlideChanged = (value) => {
  return dispatch => {
    dispatch({
      type: actionTypes.RESPONSE_RATE_SLIDE_CHANGED,
      payload: {value}
    });
    dispatch({
      type: actionTypes.CHECK_ERROR
    });
  };
};

export const bucketEnableSlideChanged = (value, tdBucket) => {
  return dispatch => {
    dispatch({
      type: actionTypes.BUCKET_ENABLE_SLIDE_CHANGED,
      payload: {value, tdBucket}
    });
    dispatch({
      type: actionTypes.SET_HAS_SELECTION
    });
    dispatch({
      type: actionTypes.CHECK_ERROR
    });
  };
};

export const bucketSizeReset = (value, tdBucket) => {
  return dispatch => {
    dispatch({
      type: actionTypes.BUCKET_SIZE_RESET,
    });
    dispatch({
      type: actionTypes.CHECK_ERROR
    });
  };
};

export const responseRateValueChanged = (value) => {
  return dispatch => {
    dispatch({
      type: actionTypes.RESPONSE_RATE_VALUE_CHANGED,
      payload: {value}
    });
    dispatch({
      type: actionTypes.CHECK_ERROR
    });
  };
};


export const passedInLastChange = (value, tdBucket) => {
  return dispatch => {
    dispatch({
      type: actionTypes.TOP_DEALERS_PASSED_IN_LAST,
      payload: {value, tdBucket}
    });
    dispatch({
      type: actionTypes.CHECK_ERROR
    });
  };
};

export const gtaPassedInLastChange = (value) => {
  return dispatch => {
    dispatch({
      type: actionTypes.GTA_PASSED_IN_LAST,
      payload: {value}
    });
    dispatch({
      type: actionTypes.CHECK_ERROR
    });
  };
};

export const timingsChanged = (value, tdBucket) => {
  return dispatch => {
    dispatch({
      type: actionTypes.TIMINGS_CHANGED,
      payload: {value, tdBucket}
    });
    dispatch({
      type: actionTypes.CHECK_ERROR
    });
  };
};

export const gtaTimingsChanged = (value) => {
  return dispatch => {
    dispatch({
      type: actionTypes.GTA_TIMINGS_CHANGED,
      payload: {value}
    });
    dispatch({
      type: actionTypes.CHECK_ERROR
    });
  };
};

export const saveSelection = saveState => {
  return dispatch => {
    dispatch({
      type: actionTypes.UPDATE_ENVIRONMENT
    });
    dispatch({
      type: actionTypes.SET_ENVIRONMENT
    });
    dispatch({
      type: actionTypes.ALERT_SAVED,
      payload: { value: saveState }
    });
  };
};
