import React from "react";
import PropTypes from "prop-types";
import Timings from "../timings/timings";
import Switch from "react-switch";

const goToAll = props => {
  const timingsArray = [15, 30, 45, 60, 180, 360, 720, 1440];

  return (
    <div
      className={`row pb-10 ${
        props.smartSelectType.toLowerCase() === "top dealers"
          ? "overlay"
          : null
      }`}
    >
      <div className="col-xs-7 col-md-7">
        <div className="col-xs-12 col-md-12 pt-15">
          <label> Always Exclude Dealers if:</label>
        </div>
        <div className="col-xs-12 col-md-12 mb-5">
          <label className="font-norm">Their response rate is less than</label>
          <Switch
            className={"margin-sides"}
            height={15}
            width={30}
            onChange={evt => props.responseRateSlideChanged(evt)}
            checked={props.gtaResponseRateActive}
            onColor="#337ab7"
            offColor="#a0a3a8"
          />
          <input
            className={`input-sm ${props.gtaResponseRateActive && !props.gtaResponseRateThreshold ? 'error' : null}`}
            type="number"
            name="fname"
            min="1"
            max="100"
            value={props.gtaResponseRateThreshold}
            onChange={evt => props.responseRateValueChanged(evt.target.value)}
            disabled={!props.gtaResponseRateActive}
          />
          <label className="sides font-norm">%</label>
        </div>
        <div className="col-xs-12 col-md-12 mb-5">
          <label className="font-norm"> They're Axed on the other side</label>
          <Switch
            className={"margin-sides"}
            height={15}
            width={30}
            onChange={evt => props.gtaAxedOnOtherSideAlwaysHandler(evt)}
            checked={props.gtaAxedOtherSideOnly}
            onColor="#337ab7"
            offColor="#a0a3a8"
          />
        </div>
        <div className="col-xs-12 col-md-12 mb-5">
          <label className="font-norm"> Inventory size is zero on my side</label>
          <Switch
            className={"margin-sides"}
            height={15}
            width={30}
            onChange={evt => props.gtaInventorySizeZeroMySideHandler(evt)}
            checked={props.gtaInventorySizeZeroMySide}
            onColor="#337ab7"
            offColor="#a0a3a8"
          />
        </div>
        <div className="col-xs-12 col-md-12">
          <label className="font-norm">They passed in last</label>
          <Switch
            className={"margin-sides"}
            height={15}
            width={30}
            onChange={evt => props.gtaPassedInLastChange(evt)}
            checked={props.gtaPassedActive}
            onColor="#337ab7"
            offColor="#a0a3a8"
          />

          <Timings
            timingsArray={timingsArray}
            dlrsLastPassed={props.gtaPassedActive}
            dealerPassedThresholdTime={props.gtaPassedThreshold}
            timingsChanged={props.gtaTimingsChanged}
          />
        </div>
      </div>
      <div className="col-xs-5 col-md-5 mb-5">
        <div className="row">
          <div className="col-xs-12 col-md-12 pt-15">
            <label> Always Include Dealers If:</label>
            <div>
              <label className="font-norm"> They're Axed on my side</label>
              <Switch
                className={"margin-sides"}
                height={15}
                width={30}
                onChange={evt => props.gtaAxedOnOtherSideNeverHandler(evt)}
                checked={props.gtaNeverExclAxedYourSide}
                onColor="#337ab7"
                offColor="#a0a3a8"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default goToAll;

goToAll.propTypes = {
  responseRateSlideChanged: PropTypes.func.isRequired,
  smartSelectType: PropTypes.string.isRequired,
  responseRateValueChanged: PropTypes.func.isRequired,
  gtaPassedInLastChange: PropTypes.func.isRequired,
  gtaResponseRateActive: PropTypes.bool.isRequired,
  gtaAxedOnOtherSideAlwaysHandler: PropTypes.func.isRequired,
  gtaAxedOnOtherSideNeverHandler: PropTypes.func.isRequired,
  gtaAxedOtherSideOnly: PropTypes.bool.isRequired,
  gtaPassedActive: PropTypes.bool.isRequired,
  gtaPassedThreshold: PropTypes.number,
  gtaTimingsChanged: PropTypes.func.isRequired,
  gtaNeverExclAxedYourSide: PropTypes.bool.isRequired
};
