import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import "./dealer-priority-item.css";
import Dropdown, { MenuItem } from "@trendmicro/react-dropdown";
import "@trendmicro/react-buttons/dist/react-buttons.css";
import "@trendmicro/react-dropdown/dist/react-dropdown.css";

const dealerPriorityItem = props => {
  const dealerQuantityArray = [
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    "All"
  ];

  let orderedSelection = [...props.dealerPriorityListSelectOptions].filter(
    dealerPriorityListSelectOption => {
      return dealerPriorityListSelectOption.priority === props.count;
    }
  );
  const getPriority = listIndex => {
    return props.dealerPriorityListSelectOptions[listIndex].priority;
  };

  let selectableOptions = props.selectableOptions.length?[...props.selectableOptions[props.tdBucket],orderedSelection[0]]:props.selectableOptions;

  return props.dealerPriorityListSelectOptions.length ? (
    <tr
      id={props.count}
      draggable="true"
      onDragStart={evt => props.setDragData(evt, props.count, props.tdBucket)}
      onDrop={evt => props.drop(evt, props.count, props.tdBucket)}
      onDragEnd={evt => props.dragEnd(evt, props.tdBucket)}
    >
      <td>
       <label
        className={
           orderedSelection !== undefined && orderedSelection[0].groups && !orderedSelection[0].group
               ? "error-dot"
               : null
        }
       >{props.count}</label>
      </td>
      <td
        className={
          orderedSelection.length && orderedSelection[0].value ? "" : "error"
        }
      >
        <Dropdown
          key={props.count}
          onSelect={eventKey =>
            props.selectionPriorityValueChange(eventKey, props.count, props.tdBucket)
          }
        >
          <Dropdown.Toggle
            btnStyle="flat"
            id={props.count}
            title={orderedSelection[0].value}
          />

          {
            <Dropdown.Menu>
              {selectableOptions.map((item, index) => {
                return item.hasGroup && props.dealerGroups.length ? (
                  <MenuItem key={item.value} eventKey={item.value}>
                    <span
                      className={
                        orderedSelection[0].groups && !orderedSelection[0].group
                          ? "error-dot"
                          : null
                      }
                    >
                      {item.value}
                    </span>
                    {props.dealerGroups.map(groupItem => {
                      return (
                        <MenuItem
                          key={`${groupItem.id}`}
                          open
                          onSelect={(eventKey, event) => {
                            props.updateGroup(
                              eventKey,
                              item.value,
                              props.count,
                              props.tdBucket
                            );
                          }}
                          eventKey={groupItem.id}
                        >
                          <span className="sides group-item">
                            {`${groupItem.groupName}`}
                          </span>
                          <input
                            type="radio"
                            checked={`${groupItem.id}` === item.group}
                            onChange={event => props}
                          />
                        </MenuItem>
                      );
                    })}
                  </MenuItem>
                ) : (
                  <MenuItem key={index} eventKey={item.value}>
                    {item.value}
                  </MenuItem>
                );
              })}
            </Dropdown.Menu>
          }
        </Dropdown>
      </td>
      <td className={orderedSelection[0].quantity ? null : "error"}>
        <select
          id="selectionPriorityOneQty"
          className="input-sm"
          value={orderedSelection[0].quantity}
          onChange={evt =>
            props.selectionPriorityQuantityChange(evt.target.value, props.count, props.tdBucket)
          }
        >
          <option value="" />
          {dealerQuantityArray.map((quantity, index) => {
            return (orderedSelection[0].value.toLowerCase() ===
              "trax active dealers" ||
              orderedSelection[0].value.toLowerCase() ===
                "top historical performers") &&
              quantity === 1 ? null : (
              <option key={index} value={quantity}>
                {quantity}
              </option>
            );
          })}
        </select>
      </td>
      <td>
        <FontAwesomeIcon
          icon={faTimes}
          onClick={() =>
            props.removeSelectionPriorityRow(getPriority(props.count - 1),props.tdBucket)
          }
          size="lg"
        />
      </td>
    </tr>
  ) : (
    ""
  );
};

export default dealerPriorityItem;

dealerPriorityItem.propTypes = {
  dealerPriorityListSelectOptions: PropTypes.array.isRequired,
  count: PropTypes.number.isRequired,
  tdBucket: PropTypes.number.isRequired,
  selectableOptions: PropTypes.array.isRequired,
  onDragStart: PropTypes.func,
  onDrop: PropTypes.func,
  onDragEnd: PropTypes.func,
  updateGroup: PropTypes.func.isRequired,
  selectionPriorityQuantityChange: PropTypes.func.isRequired,
  removeSelectionPriorityRow: PropTypes.func.isRequired,
  dealerGroups: PropTypes.array.isRequired
};
