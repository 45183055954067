import React from "react";
import PropTypes from "prop-types";

const isinIssuersIncludes = props => {
  return (
    <div className="col-xs-5 col-md-5 pt-10 sides">
      <div className="panel panel-primary">
        <div className="panel-heading">{props.type}</div>
        <div className="panel-body">
          <div className="col-xs-12 col-md-12 isins">
            <div className="form-group form-group-sm">
              <label className="col-xs-12 col-md-12 text-center">
                {" "}
                ISINS and Issuers
              </label>
              <textarea
                className="form-control"
                id="isinsIncludes"
                rows="3"
                value={props.isinIssuersTextIncludes}
                onChange={event =>
                  props.isinIssuersChangeIncludes(event.target.value)
                }
              />
            </div>
          </div>
          <div className="col-xs-12 col-md-12 isins">
            <div className="form-group form-group-sm">
              <label className="col-xs-12 col-md-12 text-center">
                {" "}
                Dealers
              </label>
              <textarea
                className={`form-control isins ${
                  !props.isinDealersTextIncludes.length &&
                  props.isinIssuersTextIncludes.length
                    ? "error"
                    : ""
                }`}
                id="isinsIncludes"
                rows="3"
                value={props.isinDealersTextIncludes}
                onChange={event =>
                  props.isinDealersChangeIncludes(event.target.value)
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default isinIssuersIncludes;

isinIssuersIncludes.propTypes = {
  type: PropTypes.string.isRequired,
  isinIssuersTextIncludes: PropTypes.string,
  isinIssuersChangeIncludes: PropTypes.func,
  isinDealersTextIncludes: PropTypes.string,
  isinDealersChangeIncludes: PropTypes.func
};
