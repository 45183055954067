import React from "react";
import PropTypes from "prop-types";
import SegmentList from "../segment-list/segment-list";

const marketSegments = props => {
  return (
    <ul id="marketSegments" className="nav-pills segment">
      {props.segments.map(segment => {
        return (
          <SegmentList
            key={segment.id}
            id={segment.id}
            isError={segment.isError}
            segment={segment.segment}
            segmentClickChanged={props.segmentClickChanged}
            selectedSegment={props.selectedSegment}
          />
        );
      })}
    </ul>
  );
};

export default marketSegments;

marketSegments.propTypes = {
  segments: PropTypes.array.isRequired,
  segmentClickChanged: PropTypes.func.isRequired,
  selectedSegment: PropTypes.string
};
