import React from "react";
import PropTypes from "prop-types";

const segmentList = props => {
  return (
    <li
      className={props.isError && props.segment === props.selectedSegment? 'page-error' : props.isError && props.segment !== props.selectedSegment ? "error" : !props.isError && props.segment === props.selectedSegment ? 'active' : null}
      onClick={() => props.segmentClickChanged(props.segment)}
    >
      {props.segment}
    </li>
  );
};

export default segmentList;

segmentList.propTypes = {
  segmentClickChanged: PropTypes.func.isRequired,
  selectedSegment: PropTypes.string
};
