import React from "react";
import PropTypes from "prop-types";
import DealerPriorityItem from "../dealer-priority-item/dealer-priority-item";
import DealerSelectionPriorityAction from "../../components/dealer-selection-priority-action//dealer-selection-priority-action";

const dealerPriorityContainer = props => {
  return (
    <div className="col-xs-7 col-md-7 sides" disabled={props.tdBucket != 1 && !props.selectedOption.active}>
      <div className="panel panel-primary">
        <div className="panel-heading">Dealer Selection Priority</div>
        <div className="panel-body">
          <table className="table">
            <thead>
              <tr>
                <th>#</th>
                <th className="col-xs-8 col-md-8">Feature</th>
                <th>Dlrs</th>
              </tr>
            </thead>
            <tbody id="priority-table" onDragOver={evt => props.dragOver(evt, props.tdBucket)}>
              {props.selectedOption.dealerSelectionPriorityList.map((item, index) => {

                return (
                  <DealerPriorityItem
                    key={index}
                    count={index + 1}
                    tdBucket={props.tdBucket}
                    dealerPriorityListSelectOptions={props.selectedOption.dealerSelectionPriorityList}
                    selectableOptions={props.selectableOptions}
                    removeSelectionPriorityRow={
                      props.removeSelectionPriorityRow
                    }
                    selectionPriorityValueChange={
                      props.selectionPriorityValueChange
                    }
                    selectionPriorityQuantityChange={
                      props.selectionPriorityQuantityChange
                    }
                    setDragData={props.setDragData}
                    drop={props.drop}
                    dragOver={props.dragOver}
                    dragEnd={props.dragEnd}
                    addNewPriority={props.addNewPriority}
                    updateGroup={props.updateGroup}
                    dealerGroups={props.dealerGroups}
                  />
                );
              })}
              <DealerSelectionPriorityAction
              	tdBucket={props.tdBucket}
                addNewPriority={props.addNewPriority}
                dealerPriorityList={props.dealerPriorityList}
                dealerSelectionsOptionsListCount={
                  props.dealerSelectionsOptionsListCount
                }
                unSelectedListCount={props.unSelectedListCount}
              />
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default dealerPriorityContainer;

dealerPriorityContainer.propTypes = {
  dealerPriorityListSelectOptions: PropTypes.array,
  tdBucket: PropTypes.number.isRequired,
  selectableOptions: PropTypes.array,
  removeSelectionPriorityRow: PropTypes.func,
  selectionPriorityValueChange: PropTypes.func,
  selectionPriorityQuantityChange: PropTypes.func,
  setDragData: PropTypes.func,
  drop: PropTypes.func.isRequired,
  dragOver: PropTypes.func.isRequired,
  addNewPriority: PropTypes.func.isRequired,
  dealerPriorityListCount: PropTypes.number,
  dealerSelectionsOptionsListCount: PropTypes.number,
  unSelectedListCount: PropTypes.number
};
